<template>
    <v-row v-if="data.telephone || data.cellphone || data.email || data.created || data.updated">
        <v-col align="left">
            <v-row>
                <v-col align="left" class="font14 textInterBold goldText">
                    <span> {{$t("demandContactInformation")}} </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" class="pt-4 textInterMedium font13"
                    v-if="true == checkShow('telephone')">
                    <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                        :fontFamilyValue="fontFamilyValue" :label="$t('telephoneFilter')" :data="telephone"
                        :isUnderline="false" />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" class="pt-4 textInterMedium font13" v-if="true == checkShow('cellphone')">
                    <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                        :fontFamilyValue="fontFamilyValue" :label="$t('mobile')" :data="data.cellphone"
                        :isUnderline="false" />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" class="pt-4 textInterMedium font13" v-if="true == checkShow('email')">
                    <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                        :fontFamilyValue="fontFamilyValue" :label="$t('email')" :data="data.email" :isUnderline="false" />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" class="pt-4 textInterMedium font13" v-if="true == checkShow('created')">
                    <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                        :fontFamilyValue="fontFamilyValue" :label="$t('created')" :data="data.created"
                        :isUnderline="false" />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" class="pt-4 textInterMedium font13" v-if="true == checkShow('updated')">
                    <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                        :fontFamilyValue="fontFamilyValue" :label="$t('modified')" :data="data.updated"
                        :isUnderline="false" />
                </v-col>
            </v-row>
            <v-row v-if="mobileBreakpoint == true && topShow.showMore != undefined" class="mt-0 justify-center">
                <v-col class="pt-0 pb-0" align="center">
                    <v-btn class="primaryColor unsetCapitalize opacity1 textDecorationBold" text plain
                        @click="() => (topShow.showMore = !topShow.showMore)">
                        {{ topShow.showMore == true ? $t("lessInfo") : $t("moreInfo") }}...
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
/*
 Name:ContactInfo
 Description: Component to render contact details using ReadFields 

 API Property:
    Data: Object containing contact info data 
        Example: {
            cellphone:"--"
            created:"27 Μάιος 2022, 1:31"
            email:"pelatis@gmail.com "
            telephone:"(+30) 210-0000000 "
            updated:"8 Ιουνίου 2022, 5:46 "

        }

    Components Used:
        ReadField
*/
import ReadField from "@/components/Fields/ReadField/ReadField";
import CoreHelper from "@/helpers/Core/core.js";
export default {
    name: "ContactInfo",
    props: ["data"],
    components: {
        ReadField,
    },
    created() {
        if (this.mobileBreakpoint == true)
            this.topShow = CoreHelper.showObjects(this.data, this.topArray, [], 4);
    },
    computed: {
        mobileBreakpoint() {
            return this.$vuetify.breakpoint.xsOnly;
        },
        telephone(){
            return Array.isArray(this.data.telephone) ? this.data.telephone.join(', '): this.data.telephone;
        }
    },
    data() {
        return {
            topArray: [
                'telephone', 'cellPhone', 'email', 'created', 'updated'
            ],
            topShow: { showKeys: [], showMore: undefined },
            fontSizeRF: "14px", //font-size for ReadingField component
            fontFamilyLabel: "InterBold", //font-family for ReadingField component label
            fontFamilyValue: "InterMedium", //font-family for ReadingField component value
            fontSizeValue: "13px"
        };
    },
    methods: {
        checkShow(objKey) {
            return this.data[objKey]
                ? this.mobileBreakpoint != true ? true
                    : (this.topShow.showKeys && this.topShow.showKeys.includes(objKey)) ||
                        this.topShow.showMore != false ? true
                        : false
                : false;
        },
    },
}
</script>