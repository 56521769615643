<template>
  <v-row>
    <v-col align="left">
      <v-row>
        <v-col align="left" class="font14 textInterBold goldText">
          <span> {{ $t("financialCriteria") }} </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="4"
          xs="12"
          v-if="data[0] && data[0] != -999999999"
        >
          <ReadField
            :fontSize="fontSizeRF"
            :fontSizeValue="fontSizeValue"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('priceFrom')"
            :data="data[0] + this.currency"
            :isUnderline="false"
          />
        </v-col>
        <v-col
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="4"
          xs="12"
          class="pt-4"
          v-if="data[1] && data[1] != 999999999"
        >
          <ReadField
            :fontSize="fontSizeRF"
            :fontSizeValue="fontSizeValue"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('priceTo2')"
            :data="data[1] + currency"
            :isUnderline="false"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
/*
 Name:EconomicCriteria
 Description: Component to render Prices of Economic Criteria

 API Property:
  data: Array containing price from and Price to of Ecomic Criteria. At first element is the price from and at second element is the price to.

  Components Used:
    ReadField
*/
import ReadField from "@/components/Fields/ReadField/ReadField";
import CoreHelper from "@/helpers/Core/core";
export default {
  name: "EconomicCriteria",
  props: {
    data: Array, // Array Containing data for Ecomomic Criteria
  },
  components: {
    ReadField,
  },
  created() {
    this.currency = CoreHelper.getCurrency(true);
  },
  data() {
    return {
      fontSizeRF: "14px", //font-size for ReadingField component
      fontFamilyLabel: "InterBold", //font-family for ReadingField component label
      fontFamilyValue: "InterMedium", //font-family for ReadingField component value
      fontSizeValue: "13px",
      currency: null,
    };
  },
};
</script>