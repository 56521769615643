<template>
<v-card :min-height="heightCard" :width="widthCard" :height="heightCard" :max-height="maxHeightCard" :elevation="0" color="transparent" :style="styleCard">
    <v-row v-if="!inCardPhoto" >
        <v-col class="flex-grow-0 pr-2">
            <v-card class="rounded" @click="openSpecificEstate" :elevation="0" style="cursor: pointer;">
                <v-img :max-height="imgHeight" :max-width="imgWidth" :height="imgHeight" :width="imgWidth" :src="tempImage" :lazy-src="tempImage" class="rounded" />
            </v-card>
        </v-col>
    </v-row>

    <v-row>
        <v-col class="flex-grow-0 pr-2" v-if="inCardPhoto">
            <v-card class="rounded" style="cursor: pointer;" @click="openSpecificEstate" :elevation="0">
                <v-img :max-height="imgHeight" :max-width="imgWidth" :height="imgHeight" :width="imgWidth" :src="tempImage" :lazy-src="tempImage" class="rounded" />
            </v-card>
        </v-col>
        <v-col style="max-width:300px">
            <v-row>
                <v-col class="text-no-wrap flex-grow-0 pb-1 textDecorationBold font16 cusTab" align="left">
                    <span> {{ ![undefined, null, "", 0].includes(price) ? price : '--'  }} </span>
                </v-col>
                <v-col class="text-no-wrap flex-grow-0 pb-1 textDecorationBold font14 veryDarkGrayColor" align="left">
                    <span> {{ ![undefined, null, "", 0].includes(type) ? type : '--'  }} </span>
                </v-col>
                <v-col class="pb-1 textDecorationBold font14 goldText" align="right">
                    <span> {{ ![undefined, null, "", 0].includes(code) ? code : '--'  }} </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0 pt-1 textDecorationBold font16 primaryColor" align="left">
                    <span> {{ ![undefined, null, "", 0].includes(title) ? title : '--'  }} </span>
                </v-col>
            </v-row>
            <v-row v-if="characteristicsArray">
                <v-col v-for="item, i in characteristicsArray" :key="i" class="flex-grow-0 pb-1 pt-2">
                    <v-row class="flex-nowrap">
                        <v-col class="align-self-center pr-0 flex-grow-0">
                            <v-img :src="require('@/assets/' + item.image)"> </v-img>
                        </v-col>
                        <v-col class="align-self-center font14 text-no-wrap flex-grow-0 ">
                            <span> {{ item.value ?  item.value : '-'  }} </span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="left" class="pt-0 pb-1">
                    <v-tooltip bottom :disabled="txtLength(address ? address : '') < 32">
                        <template v-slot:activator="{ on, attrs }">
                            <span class="font12 cusTab" align="left" v-bind="attrs" v-on="on">
                                {{ textOverFlow(address ? address : "--", 32)  }}
                            </span>
                        </template>
                        <span class="font12 cusTab" align="left">{{ address ? address : "--"  }} </span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row class="flex-nowrap">
                <v-col v-if="supportSuggestionBtn == true" class="flex-grow-0 pr-0 pt-2">
                    <v-btn class="btn primaryColor" width="100px" :disabled="disabledSuggest" outlined @click="onSuggestClick">
                        {{ $t("suggestion")}}
                    </v-btn>
                </v-col>
                <v-col v-if="supportCorspondBtn == true" class="flex-grow-0 pr-3 pt-2">
                    <v-btn class="btn primaryColor" width="174px" :disabled="disabledCorspond" outlined @click="onCorspondClick"> {{ $t("correspondinglyRealEstates")}}
                    </v-btn>
                </v-col>
                <v-col v-if="supportEmailBtn == true" class="flex-grow-0 pr-3 pt-2">
                    <v-btn class="btn primaryColor" width="90px" :disabled="disabledEmail" outlined @click="onEmailClick"> {{ $t("email")}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-card>
</template>

<script>
/*
 Name:EstateInfoCard
 Description: Component to render card with a image, price, subtitle and a description under the subtitle.

 API Property:
 widthCard: Width of card component
 heightCard: Height of card component
 maxHeightCard: Max height of card component
 inCardPhoto: Boolean to be the photo at the left of the characteristics
 image: Image of card
 imgWidth: Image width
 imgHeight: Image height
 price: String containing price information 
 type: String containing type information 
 code: String containing code information 
 title: String containing title information 
 address: String containing address information 
 styleCard: String contating style for the card
 characteristicsArray: Array with image path and value for every property. 
    For example characteristicsArray: 
        [
            {
                image: "realEstateSilder/bedroom.png",
                value: 10,
            },
            {
                image: "realEstateSilder/bathroom.png",
                value: 20,
            },
            {
                image: "realEstateSilder/sqft.png",
                value: 30,
            },
        ],
id: Id for the estate id to open from the parent
supportSuggestionBtn: Boolean indicating if SuggestionBtn will be shown or hidden
suggestBtnClicked: Function called in parent
supportCorspondBtn: Boolean indicating if CorspondBtn will be shown or hidden
corspondBtnClicked: Function called in parent
supportEmailBtn: Boolean indicating if emailBtn will be shown or hidden
emailBtnClicked: Function called in parent
getSpecificEstate: Function to open the estate when the user press on image
disabledSuggest: Boolean for the disables suggest button
disabledCorspond: Boolean for the disables correspond button
disabledEmail: Boolean for the disables email button
*/

import CoreHelper from "@/helpers/Core/core.js";
export default {
    name: "EstateInfo",
    props: {
        widthCard: {
            type: Number,
            default: 587
        },
        heightCard: Number, //{ type: Number, default: 151 },
        maxHeightCard: Number,
        image: String,
        absolutePath: {
            type: Boolean,
            default: false
        },
        inCardPhoto: {
            type: Boolean,
            default: true
        },
        imgWidth: {
            type: Number,
            default: 280
        },
        imgHeight: {
            type: Number,
            default: 151
        },
        id: Number,
        price: [String, Number],
        type: String,
        code: String,
        title: String,
        address: String,
        styleCard: String,
        characteristicsArray: Array,
        supportSuggestionBtn: {
            type: Boolean,
            default: true
        },
        suggestBtnClicked: Function,
        supportCorspondBtn: {
            type: Boolean,
            default: true
        },
        supportEmailBtn: {
            type: Boolean,
            default: false
        },
        corspondBtnClicked: Function,
        getSpecificEstate: Function,
        emailBtnClicked: Function,
        disabledSuggest: {type: Boolean, default: false},
        disabledCorspond: {type: Boolean, default: false},
        disabledEmail: {type: Boolean, default: false},
    },
    computed: {
        tempImage() {
            return CoreHelper.getImagePath({
                path: this.image,
                absolutePath: false
            });
        },
    },
    methods: {
        openSpecificEstate() {
            this.getSpecificEstate(this.id);
        },
        txtLength(txt) {
            try {
                return txt.length;
            } catch {
                return 0;
            }
        },
        textOverFlow(text, length) {
            try {
                return text.length <= length ? text : text.substr(0, length) + " ..";
            } catch {
                return text;
            }
        },
        onCorspondClick() {
            this.suggestBtnClicked()
        },
        onSuggestClick() {
            this.corspondBtnClicked()
        },
        onEmailClick() {
            this.emailBtnClicked()
        },
    },
};
</script>

<style lang="scss" scoped>
.btn {
    text-transform: unset !important;
    color: #263573 !important;
    background-color: white;
    font-weight: 500 !important;
    border-color: rgba(112, 112, 112, 0.39);
    border-radius: 0;
    height: 25px !important;
}
</style>
