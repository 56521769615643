<template>
  <v-col>
    <v-row>
      <v-col align="left" class="textInterBold font14 goldText">
        <span> {{ $t("realEstateLocation") }} </span>
      </v-col>
    </v-row>
    <v-row v-for="(location, i) in locations.descriptions" :key="i">
      <v-col align="left" class="textInterMedium font13 cusTab pb-0 pt-2">
        <span>
          {{ location }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Map
          :locationMarkers="locationMarkers"
          mapHeight="381px"
          mapWidth="100%"
          mapStyle=" max-width: 658px;"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
/*
 Name:MapInfo
 Description: Component have the locaitons list and set markers on map dynamically from gps data in list

 API Property:
        locations: Object containing the data to form for the locations and the markers on map, Example:
            {"descriptions":["Αδριανείου 2, Αθήνα 115 25, Ελλάδα","Χαρ. Τρικούπη, Αθήνα, Ελλάδα","Αμπελόκηποι | Γηροκομείο","Αμπελόκηποι | Νέα Φιλοθέη"],
            "gps":[[37.99681160000001,23.7693574],[37.9856811,23.7386136]]}

      
    Components Used:
        Map
*/
import Map from "@/components/Maps/Map";
export default {
  name: "MapInfo",
  props: {
    locations: Object,
  },
  components: {
    Map,
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    locationMarkers() {
      let locs = [];
      try {
        locs = this.locations && this.locations.gps && this.locations.gps.length > 0 ? this.locations.gps : [{ position: { lat: "", lng: "" }, type: "data" }];
      } catch {
        locs = [{ position: { lat: "", lng: "" }, type: "data" }];
      }
      return locs;
    },
  },
};
</script>
