<template>
<v-row>
    <v-col align="left">
        <v-row>
            <v-col align="left">
                <span class="font14 textInterBold goldText"> {{$t("details")}} </span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" v-if="true == checkTopShow(0)">
                <v-row>
                    <v-col class="textInterBold font13 primaryColor pb-2" align="left">
                        <span> {{$t("contactPerson")}}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pr-0" align="left" style="cursor: pointer" @click="openContactPerson">
                        <AvatarField :size="30" :data="data.contactPersonPhoto ? data.contactPersonPhoto
                                : (data.contactPersonName && data.contactPersonName.charAt(0) ? data.contactPersonName.charAt(0) : '') + (data.contactPersonSurName && data.contactPersonSurName.charAt(0) ? data.contactPersonSurName.charAt(0) : '')" :isImg="data.contactPersonPhoto ? true : false" backgroundColor="#77BD86" :showName="true" :nameContent="(data.contactPersonName ? data.contactPersonName : '') + ' ' + (data.contactPersonSurName ? data.contactPersonSurName : '')" fontSizeName="13px; font-family: InterBold; color: #3A3A40" fontSizeImg="15px; font-family: Inter;" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" class="pr-0" v-if="true == checkTopShow(1)">
                <v-row>
                    <v-col class="textInterBold font13 primaryColor pb-2" align="left">
                        <span> {{$t("responsible")}} </span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pr-0" align="left" style="cursor: pointer" @click="openResponsiblePerson">
                        <AvatarField :size="30" :data="data.responsiblePersonPhoto ? data.responsiblePersonPhoto
                                : (data.responsiblePersonName && data.responsiblePersonName.charAt(0) ? data.responsiblePersonName.charAt(0) : '') + (data.responsiblePersonSurName && data.responsiblePersonSurName.charAt(0) ? data.responsiblePersonSurName.charAt(0) : '')" :isImg="data.responsiblePersonPhoto ? true : false" backgroundColor="#77BD86" :showName="true" :nameContent="(data.responsiblePersonName ? data.responsiblePersonName : '') + ' ' + (data.responsiblePersonSurName ? data.responsiblePersonSurName : '')" fontSizeName="13px; font-family: InterBold; color: #3A3A40" fontSizeImg="15px" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" class="pr-0" v-if="true == checkTopShow(2)">
                <v-row>
                    <v-col class="textInterBold font13 primaryColor pb-2" align="left">
                        <span> {{$t("addedBy")}} </span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pr-0" align="left" style="cursor: pointer" @click="openAddedFromPerson">
                        <AvatarField :size="30" :data="data.addedFromPhoto ? data.addedFromPhoto
                                : (data.addedFromName && data.addedFromName.charAt(0) ? data.addedFromName.charAt(0) : '') + (data.addedFromSurName && data.addedFromSurName.charAt(0) ? data.addedFromSurName.charAt(0) : '')" :isImg="data.addedFromPhoto ? true : false" backgroundColor="#77BD86" :showName="true" :nameContent="(data.addedFromName ? data.addedFromName : '') + ' ' + (data.addedFromSurName ? data.addedFromSurName : '')" fontSizeName="13px; font-family: InterBold; color: #3A3A40" fontSizeImg="15px" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-if="mobileBreakpoint == true && topShow.showMore != undefined" class="mt-0">
            <v-col class="pt-0 pb-0">
                <v-btn class="primaryColor unsetCapitalize opacity1 textDecorationBold" text plain @click="() => (topShow.showMore = !topShow.showMore)">
                    {{ topShow.showMore == true ? $t("lessPersons") : $t("morePersons") }}...
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="mobileBreakpoint == true">
            <v-col align="left" class="font14 textInterBold goldText">
                <span> {{$t("details")}}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" v-if="true == checkBotShow('status')">
                <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue" :label="$t('condition')" :data="$t(data.status)" :isUnderline="false" />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" v-if="true == checkBotShow('created')">
                <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue" :label="$t('created')" :data="data.created" :isUnderline="false" />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" v-if="true == checkBotShow('updated')">
                <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue" :label="$t('modified')" :data="data.updated" :isUnderline="false" />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" v-if="true == checkBotShow('nextFollowUp')">
                <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue" :label="$t('nextFollowUp')" :data="data.nextFollowUp" :isUnderline="false" />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" v-if="true == checkBotShow('code')">
                <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue" :label="$t('demandCode')" :data="data.code" :isUnderline="false" />
            </v-col>
        </v-row>
    </v-col>
</v-row>
</template>

<script>
/*
 Name:DemandDetails
 Description: Component that can be use render multiple avatarFields and other data like images, names and surname of agents.

 API Property:
        fetchContactById: Function,
        data: Object containing the data to form the avatarFields and details of agents, Example:
            detailsData: {
                status: "Ανατέθηκε ",
                created: "27 Μάιος 2022, 1:31",
                updated: "8 Ιουνίου 2022, 5:46 ",
                nextFollowUp: "26 Ιουνίου 2022, 6:00",
                code: "100006",
                contactPersonName: "Μαρία ",
                contactPersonSurName: "Παπαπέτρου",
                contactPersonPhoto: ""
                contactId: 234234,
                responsiblePersonName: "Αλεξάνδρα",
                responsiblePersonSurName: "Παπαναστασίου",
                responsiblePersonPhoto: ""
                agentId: "23123"
                addedFromName: "Ιωάννης",
                addedFromSurName: "Ζάκα"
                addedFromPhoto: ""
                addedFromId: "123213"
            },
    Components Used:
        ReadField
        AvatarField
*/
import ReadField from "@/components/Fields/ReadField/ReadField";
import AvatarField from "@/components/Fields/AvatarField/AvatarField";
import CoreHelper from "@/helpers/Core/core.js";
export default {
    name: "DemandDetails",

    props: {
        data: Object,
        openContact: Function,
    },

    components: {
        ReadField,
        AvatarField,
    },
    data() {
        return {
            fontSizeRF: "14px", //font-size for ReadingField component
            fontFamilyLabel: "InterBold", //font-family for ReadingField component label
            fontFamilyValue: "InterMedium", //font-family for ReadingField component value
            fontSizeValue: "13px",
            topArray: [
                ["contactPersonPhoto", "contactPersonName", "contactPersonSurName"],
                ["responsiblePersonPhoto", "responsiblePersonName", "responsiblePersonSurName"],
                ["addedFromPhoto", "addedFromName", "addedFromSurName"],
            ],
            topShow: {
                showKeys: [],
                showMore: undefined
            },
            botArray: [
                "status",
                "created",
                "updated",
                "nextFollowUp",
                "code"
            ],
            botShow: {
                showKeys: [],
                showMore: undefined
            },
        };
    },
    created() {
        if (this.mobileBreakpoint == true) {
            this.topShow = CoreHelper.showDualObjects(this.data, this.topArray, 2);
            this.botShow = CoreHelper.showObjects(this.data, this.botArray, undefined, 2);
        }
    },
    computed: {
        mobileBreakpoint() {
            return this.$vuetify.breakpoint.xsOnly;
        },
    },
    methods: {
        openContactPerson() {
            this.openContact(this.data.contactId);
        },

        openResponsiblePerson() {
             this.openContact(this.data.agentId);
        },

        openAddedFromPerson() {
            this.openContact(this.data.addedFromId);
        },
        checkTopShow(objIndex) {
            return this.data[this.topArray[objIndex][1]] || this.data[this.topArray[objIndex][2]] ? this.mobileBreakpoint != true ? true :
                (this.topShow.showKeys && this.topShow.showKeys.includes(this.topArray[objIndex][0])) || this.topShow.showMore != false ? true :
                false :
                false;
        },
        checkBotShow(objKey) {
            return this.data[objKey] ? this.mobileBreakpoint != true ? true :
                (this.botShow.showKeys && this.botShow.showKeys.includes(objKey)) || this.botShow.showMore != false ? true :
                false :
                false;
        },
    },
}
</script>
