<template >
    <v-card max-width="752" style="text-align: start" class="pa-10" :ripple="false">
        <v-col>
            <v-row>
                <v-col class="">
                    <span class="textDecorationBold font20 primaryColor ">{{$t("offer")}}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <span class="textDecorationBold font14 " style="color: 000000;">{{$t("createNewOffer")}}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-divider></v-divider>
            </v-row>
            <v-row>
                <v-col class="primaryColor " col="12" sm="6" md="12" xs='12'>
                    <v-checkbox :label="$t('impOrderEsign')" hide-details class="textDecorationBold font15 primaryColor"
                        color="#263573" v-model="isSelectImportFromEsign"></v-checkbox>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="primaryColor py-0" col="12" sm="6" md="5" xs='12' v-if="isSelectImportFromEsign">
                    <v-autocomplete v-model="esignCode" :items="esignList" :loading="isLoading" hide-no-data
                        item-text="desc" object-return :label="$t('findEsignCode')" :error-messages="esignCodeError"
                        return-object class="font14 gunPowderColor " attach>
                        <v-icon slot="append">
                            mdi-magnify
                        </v-icon>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row class="">
                <v-col class="primaryColor py-0" cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                    <v-select :items="suggestionList" :label="$t('choiceSuggestions') + '*'" v-model="estateSuggestion"
                        item-text='desc' :error-messages="estateSuggestionError" return-object>
                    </v-select>
                </v-col>
                <v-col class="primaryColor py-0" cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                    <v-autocomplete v-model="estateByCode" :items="estateList" :loading="isLoading" hide-no-data
                        item-text="desc" :error-messages="estateByCodeError" object-return
                        :label="$t('realEstateCodeSearch') + '*'" return-object class="font14 gunPowderColor" attach>
                        <v-icon slot="append">
                            mdi-magnify
                        </v-icon>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row class="mt-n3">
                <v-col class="primaryColor py-0" cols="12" sm="6" md="6" xs="12" xl="6" lg="6">
                    <v-select v-model="offerStatus" :label="$t('typeOfOffer')" :items="offerStatusList"
                        hide-details="auto" item-text="text" item-value="value" :error-messages="offerStatusError">
                        <template v-slot:selection="{ item }">
                            <v-row>
                                <v-col align="left" class="text-no-wrap">
                                    <span> {{ $t(item.text) }} </span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-list>
                                <v-list-item-title>
                                    <v-row>
                                        <v-col align="left" class="text-no-wrap">
                                            <span> {{ $t(item.text) }} </span>
                                        </v-col>
                                    </v-row>
                                </v-list-item-title>
                            </v-list>
                        </template>
                    </v-select>
                </v-col>

                <v-col class="primaryColor py-0" cols="12" sm="6" md="6" xs="12" xl="6" lg="6">
                    <v-select v-model="offerType" :label="$t('typeOfOffer')" :items="availableForList"
                        hide-details="auto" item-text="text" item-value="value" :error-messages="offerStatusError">
                        <template v-slot:selection="{ item }">
                            <v-row>
                                <v-col align="left" class="text-no-wrap">
                                    <span> {{ $t(item.text) }} </span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-list>
                                <v-list-item-title>
                                    <v-row>
                                        <v-col align="left" class="text-no-wrap">
                                            <span> {{ $t(item.text) }} </span>
                                        </v-col>
                                    </v-row>
                                </v-list-item-title>
                            </v-list>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row class="mt-5" >
                <v-col class="primaryColor py-0" cols="12" sm="6" md="6" xs="12" xl="6" lg="6">
                    <v-text-field :label="$t('offerPrice') + '*'" hide-details="auto" v-model='offerPrice'
                        :error-messages="offerPriceError" class="font14 gunPowderColor">
                        <span slot="append" class="primaryColor font14 textDecorationBold mt-1">{{ currency }}</span>
                    </v-text-field>
                </v-col>
                <v-col class="primaryColor pt-4" cols="12" sm="6" md="6" xs="12" xl="6" lg="6">
                    <v-menu ref="dateField1" v-model="dateField1" :close-on-content-click="false"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense :label="$t('offerDate2') + '...'" clearable v-model="offerDateFormat"
                                class='font14 gunPowderColor' prepend-inner-icon="mdi-calendar" @blur="
                                    offerDate = parseDate(
                                        offerDateFormat,
                                        'offerDate'
                                    )
                                ">
                                <template v-slot:prepend-inner>
                                    <v-icon v-bind="attrs" v-on="on" size="30">mdi-calendar</v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker locale="el" v-model="offerDate" scrollable @input="dateField1 = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="primaryColor py-0">
                    <v-checkbox v-model="hasProposal" :label="$t('ownerCounterproposal')" hide-details
                        class="textDecorationBold font15 primaryColor py-0"></v-checkbox>
                </v-col>
            </v-row>
            <v-row v-if="hasProposal">
                <v-col class="primaryColor" cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                    <v-text-field :label="$t('counterproposalPrice') + '*'" hide-details="auto"
                        class="font14 gunPowderColor" v-model="counterProposalPrice"
                        :error-messages="counterProposalPriceError">
                        <span slot="append" class="primaryColor font14 textDecorationBold mt-1">{{ currency }}</span>
                    </v-text-field>
                </v-col>
                <v-col class="primaryColor pt-6 " cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                    <v-menu ref="dateField2" v-model="dateField2" :close-on-content-click="false"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense :label="$t('counterproposalDate') + '...'" clearable
                                v-model="counterProposalDateFormat" class='font14 gunPowderColor '
                                prepend-inner-icon="mdi-calendar" @blur="
                                    counterProposalDate = parseDate(
                                        counterProposalDateFormat,
                                        'counterProposalDate'
                                    )
                                ">
                                <template v-slot:prepend-inner>
                                    <v-icon v-bind="attrs" v-on="on" size="30">mdi-calendar</v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker locale="el" v-model="counterProposalDate" scrollable @input="dateField2 = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row class="mt-6">
                <v-col class="primaryColor" :align="mobileBreakpoint != true ? 'end' : 'center'">
                    <v-btn class="btn" width="157" height="42" outlined @click="onCancel">
                        {{$t("cancel")}}
                    </v-btn>
                </v-col>
                <v-col :align="mobileBreakpoint != true ? 'start' : 'center'">
                    <v-btn width="157" height="42" class="btn-custom" color="error" @click="onsubmit">
                        {{$t("send")}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-card>
</template>
<script>
/*
Name: DemandOffer
Description: Component for creaton of a demand offer 
confirmHandler:The Action for confirm Button
cancelHandler:The Action for Cancel Button
*/

const isFormattedDate = (dateString) => {
    if (dateString == null) return false;
    let regEx =
        /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
    return dateString.match(regEx) != null;
};
import { required, requiredIf, numeric } from 'vuelidate/lib/validators'
import OfferLists from "@/static/offers/offers.js";
import { mapGetters } from "vuex";
import CoreHelper from "@/helpers/Core/core";
import i18n from '../../i18n/i18n';

export default {
    name: "DemandOffer",
    props: {
        submitHandler: Function,
        cancelHandler: Function,
    },
    data() {
        return {
            isSelectImportFromEsign: true,
            hasProposal: true,
            esignCode: null,
            estateSuggestion: null,
            estateByCode: null,
            dateField1: null,
            counterProposalDateFormat: null,
            counterProposalDate: null,
            offerDateFormat: null,
            offerDate: null,
            offerStatus: null,
            offerType: null,
            offerPrice: null,
            counterProposalPrice: null,
            dateField2: null,
            currency: null,
            offerStatusList: OfferLists.offerStatusList,
            esignList: [
                {
                    desc: 'Test1',
                    id: 1,
                },
                {
                    desc: 'Test2',
                    id: 2,
                },
                {
                    desc: 'Test3',
                    id: 3,
                },
                {
                    desc: 'Test4',
                    id: 4,
                },
                {
                    desc: 'Test5',
                    id: 5,
                },
                {
                    desc: 'Test6',
                    id: 6,
                },
                {
                    desc: 'Test7',
                    id: 7,
                },
                {
                    desc: 'Test8',
                    id: 8,
                },
                {
                    desc: 'Test9',
                    id: 9,
                },
                {
                    desc: 'Test10',
                    id: 10,
                },
                {
                    desc: 'Test12',
                    id: 12,
                },
                {
                    desc: 'Test13',
                    id: 13,
                },
            ],
            suggestionList: [
                {
                    desc: 'Test1',
                    id: 1,
                },
                {
                    desc: 'Test2',
                    id: 2,
                },
            ],
            estateList: [
                {
                    desc: 'Test1',
                    id: 1,
                },
                {
                    desc: 'Test2',
                    id: 2,
                },
            ],
            statusList: [
                {
                    desc: 'Test1',
                    id: 1,
                },
                {
                    desc: 'Test2',
                    id: 2,
                },
            ],
            typeList: [
                {
                    desc: 'Test1',
                    id: 1,
                },
                {
                    desc: 'Test2',
                    id: 2,
                },
            ],
            isLoading: false
        }
    },
    created() {
        this.currency = CoreHelper.getCurrency(false);
    },
    watch: {
        offerDate() {
            this.offerDateFormat = this.formatDate(this.offerDate);
        },
        counterProposalDate() {
            this.counterProposalDateFormat = this.formatDate(this.counterProposalDate);
        },
    },
    computed: {

        ...mapGetters([
            "availableForList",
        ]),
        mobileBreakpoint() {
            return this.$vuetify.breakpoint.xsOnly;
        },
        estateSuggestionError() {
            const errors = [];
            if (!this.$v.estateSuggestion.$dirty) return errors;
            if (!this.$v.estateSuggestion.required) errors.push(i18n.t("requiredField"));
            return errors;
        },
        estateByCodeError() {
            const errors = [];
            if (!this.$v.estateByCode.$dirty) return errors;
            if (!this.$v.estateByCode.required) errors.push(i18n.t("requiredField"));
            return errors;
        },
        offerStatusError() {
            const errors = [];
            if (!this.$v.offerStatus.$dirty) return errors;
            if (!this.$v.offerStatus.required) errors.push(i18n.t("requiredField"));
            return errors;
        },
        offerPriceError() {
            const errors = [];
            if (!this.$v.offerPrice.$dirty) return errors;
            if (!this.$v.offerPrice.required) errors.push(i18n.t("requiredField"));
            if (!this.$v.offerPrice.numeric) errors.push("Input must be a number");
            return errors;
        },
        counterProposalPriceError() {
            const errors = [];
            if (!this.$v.counterProposalPrice.$dirty) return errors;
            if (!this.$v.counterProposalPrice.required) errors.push(i18n.t("requiredField"));
            if (!this.$v.counterProposalPrice.numeric) errors.push("Input must be a number");
            return errors;
        },
        esignCodeError() {
            const errors = [];
            if (!this.$v.esignCode.$dirty) return errors;
            if (!this.$v.esignCode.required) errors.push(i18n.t("requiredField"));
            return errors;
        },
    },
    validations() {
        const self = this;
        return {
            counterProposalPrice: {
                required: requiredIf(function () {
                    return self.hasProposal == true;
                }),
                numeric
            },
            esignCode: {
                required: requiredIf(function () {
                    return self.isSelectImportFromEsign == true;
                }),
            },
            estateSuggestion: {
                required,
            },
            estateByCode: {
                required,
            },
            offerStatus: {
                required,
            },
            offerPrice: {
                required,
                numeric
            },
        };
    },
    methods: {
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        parseDate(date, dateName) {
            if (!date || !isFormattedDate(date)) {
                if (dateName == "offerDate") {
                    this.offerDate = null;
                    this.offerDateFormat = null;
                } else if (dateName == "counterProposalDate") {
                    this.counterProposalDateFormat = null;
                    this.counterProposalDate = null;
                }
                return null;
            }
            const [day, month, year] = date.split("/");
            return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        },
        onCancel() {
            this.cancelHandler()
        },
        onsubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) return false;
        }
    }
}
</script>
<style scoped>
.btn {
    text-transform: unset !important;
    color: #263573 !important;
    background-color: white;
    font-weight: 500 !important;
    font-family: CenturyGothicBold !important;
}

.btn-custom {
    text-transform: capitalize !important;
    font-family: CenturyGothicBold !important;
    font-weight: 500 !important;
    font-size: 15px;
    background-color: white;
}
</style>