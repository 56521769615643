<template>
    <v-card style="text-align: center" class="pa-10">
        <v-row>
            <v-col>
                <span class="textDecorationBold font20 primaryColor">
                    {{ $t("demandAssignment")}}
                </span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <span class="textDecorationBold font14" style="color: 000000;"> {{ $t("demandHandlePartner")}}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pb-5">
                <v-select :items="partner" label="Επιλογή Συνεργάτη..." item-text="name" return-object
                    v-model="selectedPartner"></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-divider class="pt-7 ">

            </v-divider>
        </v-row>
        <v-row style="text-align: center" class="pa-0">
            <v-col :align="mobileBreakpoint != true ? 'end' : 'center'">
                <v-btn class="btn " outlined width="157" height="42" @click="onCancel"> {{$t("cancel")}} </v-btn>
            </v-col>
            <v-col :align="mobileBreakpoint != true ? 'start' : 'center'">
                <v-btn class="btn-custom" color="error" width="157" height="42" @click="onConfirm">
                    {{$t("confirmation")}}
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
/*
Name: DemandAssignment
Description: Component that consists of Assignment Demandment

  API Property
    confirmHandler:The Action for confirm Button
    cancelHandler:The Action for Cancel Button
    partner: Array that contains the list of data for selected Partner
*/

export default {
    name: "DemandAssignment",
    props: {
        cancelHandler: Function,
        confirmHandler: Function,
        partner: Array
    },
    data() {
        return {
            selectedPartner: null
        }
    },
    methods: {
        onCancel() {
            this.cancelHandler()
        },
        onConfirm() {
            this.confirmHandler(this.selectedPartner)

        }
    },
    computed: {
        mobileBreakpoint() {
            return this.$vuetify.breakpoint.xsOnly;
        },
    }
}
</script>
<style scoped>
.btn-custom {
    text-transform: capitalize !important;
    font-family: CenturyGothicBold !important;
    font-weight: 500 !important;
    font-size: 15px;
    background-color: white;
}

.btn {
    text-transform: unset !important;
    color: #263573 !important;
    background-color: white;
    font-weight: 500 !important;
    font-family: CenturyGothicBold !important;
}
</style>