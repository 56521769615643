<template>
  <v-col align="center" class="pb-2">
    <v-row>
      <v-col class="pt-4" align="left">
        <span class="textFontDecoration primaryColor font14"
          >{{ $t("weMatched") }} {{ totalResult }}
          {{ totalResult > 0 ? $tc("properties", 2) : $tc("properties", 1) }}
        </span>
      </v-col>
      <v-col>
        <v-row class="justify-end">
          <v-col class="flex-grow-0 pr-2">
            <v-select
              color="success"
              item-color="primary"
              style="
                font-size: 15px;
                font-family: CenturyGothicBold;
                font-weight: 500;
              "
              v-model="matchingChoice"
              :items="propertiesList"
              outlined
              hide-details="auto"
              item-text="label"
              item-value="id"
              dense
            >
              <template v-slot:selection="{ item }">
                <v-row>
                  <v-col align="center" class="text-no-wrap">
                    <span> {{ $t(item.text) }} </span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item="{ item }">
                <v-list>
                  <v-list-item-title>
                    <v-row>
                      <v-col align="center" class="text-no-wrap">
                        <span> {{ $t(item.text) }} </span>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list>
              </template>
            </v-select>
          </v-col>
          <v-col class="flex-grow-0">
            <v-select
              :items="pagesList"
              dense
              v-model="options.items"
              color="primary"
              @change="optionsRefresh"
              item-color="primary"
              class="textDecorationBold font14"
              style="max-width: 75px"
              height="35"
              hide-details
              outlined
              :loading="loading"
            >
              <template v-slot:selection="{ item }">
                <v-row>
                  <v-col align="center" class="text-no-wrap">
                    <span> {{ $t(item.text) }} </span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item="{ item }">
                <v-list>
                  <v-list-item-title>
                    <v-row>
                      <v-col align="center" class="text-no-wrap">
                        <span> {{ $t(item.text) }} </span>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row
          class="pb-7"
          v-if="loading || dataFoundStatus != 'true'"
          justify="center"
        >
          <LoaderGif v-if="loading" :white="true" />
          <NoData v-else-if="this.dataFoundStatus == 'false'" />
          <ServerProblem v-else-if="this.dataFoundStatus == 'error'" />
        </v-row>

        <v-row
          v-else-if="!loading && dataFoundStatus == 'true'"
          class="noScrollBar"
          :style="
            !mobileBreakpoint
              ? `min-height: 320px !important; overflow: auto;`
              : ''
          "
          :class="{ 'justify-center': mobileBreakpoint }"
        >
          <EstateInfoCard
            v-for="d in data"
            :key="d._id"
            :image="d.MainPhoto"
            styleCard="margin: 20px 20px 20px 10px;"
            :characteristicsArray="d.characteristicsArray"
            :price="
              d.Price
                ? new Intl.NumberFormat('en-DE').format(d.Price) +
                  ' ' +
                  currency
                : ''
            "
            :type="d.Task"
            :code="d.EstateCode"
            :title="d.Type"
            :address="d.Area"
            :id="d._id"
            :inCardPhoto="false"
            :getSpecificEstate="getSpecificEstate"
            :widthCard="279"
            :supportSuggestionBtn="true"
            :supportCorspondBtn="true"
            :disabledSuggest="true"
            :disabledCorspond="true"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pb-3">
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row class="pb-6">
      <v-footer
        v-if="!loading || dataFoundStatus == 'true'"
        style="background-color: white"
        absolute
      >
        <v-row class="mb-0" style="max-width: 100% !important">
          <v-col align="left" class="main-pgt pt-4 footerCl">
            {{ $t("footerP1") }} {{ options.page ? options.page : 0 }}
            {{ $t("footerP2") }} {{ options.items ? options.items : 0 }}
            {{ $t("footerP3") }} {{ totalResult ? totalResult : 0 }}
            {{ $t("footerP4") }}
          </v-col>
          <v-col
            align="right"
            class="flex-grow-0 pr-0 mr-n4 pt-0"
            v-if="data.length > 0"
          >
            <v-pagination
              v-model="options.page"
              :total-visible="6"
              @input="fetchMatchings"
              :length="totalPages"
            >
            </v-pagination>
          </v-col>
        </v-row>
      </v-footer>
    </v-row>
  </v-col>
</template>

<script>
/*
 Name:MatchingProperties
 Description: Component that shows matching properties for the demand

 API Property:
 dataId: Object for the data from the parent
 id: Number/String Id to get the matching properties 
 pagesList: list for the page refresh options Example:
        [{  
            id: 0,
            text: "3",
            value: 3,
        },
        {
            id: 1,
            text: "5",
            value: 5,
        }]
 getSpecificEstate: Function from the parent to open a estate
 type: String to indetify if the demane is specific or general ("general","spefiic")

    Components Used:
        EstateInfoCard
        LoaderGif
        ServerProblem
        NoData

    Endpoints Functions Called:
      -RequestsDemandRepo
        getGeneralMatcher
        getPropertyMatcher
*/

import EstateInfoCard from "@/components/Demands/Slider/EstateInfoCard.vue";
import LoaderGif from "@/components/Popups/LoaderGif.vue";
import ServerProblem from "@/components/Popups/ServerProblem.vue";
import NoData from "@/components/Popups/NoData.vue";
import CoreHelper from "@/helpers/Core/core";
import Repository from "@/services/Repository";
import i18n from "@/i18n/i18n";
import DemandsStaticLists from "@/static/demands/demands.js";

const RequestsDemandRepo = Repository.get("reqDemand");

export default {
  name: "MatchingProperties",
  components: {
    EstateInfoCard,
    LoaderGif,
    ServerProblem,
    NoData,
  },
  props: {
    dataId: Object,
    pagesList: {
      type: Array,
      default: () => [
        {
          id: 0,
          text: "3",
          value: 3,
        },
        {
          id: 1,
          text: "5",
          value: 5,
        },
        {
          id: 2,
          text: "10",
          value: 10,
        },
      ],
    },
    type: String,
    getSpecificEstate: Function,
  },
  async created() {
    this.currency = CoreHelper.getCurrency(true);
    await this.fetchMatchings();
  },
  data() {
    return {
      data: {},
      matchingChoice: 0,
      options: {
        page: 1,
        items: 3,
      },
      currency: null,
      loading: false,
      propertiesList: DemandsStaticLists.propertiesList,
      totalResult: 0,
      totalPages: 0,
      dataFoundStatus: null,
    };
  },
  watch: {
    matchingChoice() {
      this.fetchMatchings();
    },
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    getCharacteristicValue(array, val) {
      if (array[val] != undefined) {
        return array[val];
      } else {
        return "-";
      }
    },
    async fetchMatchings() {
      console.log("IN THIS ONE");
      if (this.type == "general") {
        await this.fetchGeneralMatching();
      } else {
        await this.fetchSpecificMatching();
      }
    },
    async optionsRefresh() {
      this.options.page = 1;
      this.fetchMatchings();
    },
    async fetchGeneralMatching() {
      this.loading = true;
      try {
        let object = {};
        if (this.matchingChoice) {
          object = { show_only_my_estates: true };
        }
        let params = {
          page: this.options.page - 1,
          items: this.options.items,
        };
        let resp = await RequestsDemandRepo.getPropertyMatcher(
          object,
          this.dataId._id,
          params
        );
        //let resp = await RequestsDemandRepo.getGeneralMatcher(this.id);
        this.data =
          resp == undefined ||
          resp.data == undefined ||
          resp.data.related_properties == undefined
            ? []
            : resp.data.related_properties;
        if (this.data.length > 0) {
          this.dataFoundStatus = "true";
          this.totalResult = resp.data.related_properties_num;
          this.totalPages = Math.ceil(this.totalResult / this.options.items);
          console.log(resp.data.related_properties);
          this.data.map((c) => {
            c.characteristicsArray = [
              {
                image: "realEstateSilder/bedroom.png",
                value: this.getCharacteristicValue(
                  c.characteristics,
                  "bedrooms"
                ),
              },
              {
                image: "realEstateSilder/bathroom.png",
                value: this.getCharacteristicValue(
                  c.characteristics,
                  "bathrooms"
                ),
              },
              {
                image: "realEstateSilder/sqft.png",
                value: c?.Unit ? c?.Unit + " " + i18n.t("sqftTable") : "-",
              },
            ];
          });
        } else {
          this.dataFoundStatus = "false";
          this.totalResult = 0;
          this.totalPages = 0;
        }
        this.loading = false;
      } catch (e) {
        this.dataFoundStatus = "error";
        this.data = [];
        this.loading = false;
        this.totalResult = 0;
        this.totalPages = 0;
        throw e;
      }
    },
    async fetchSpecificMatching() {
      this.loading = true;
      try {
        let object = {};
        if (this.matchingChoice) {
          object = { show_only_my_estates: true };
        }
        let params = {
          page: this.options.page - 1,
          items: this.options.items,
        };
        let resp = await RequestsDemandRepo.getPropertyMatcher(
          object,
          this.dataId.Estate._id,
          params
        );
        this.data =
          resp == undefined ||
          resp.data == undefined ||
          resp.data.related_properties == undefined
            ? []
            : resp.data.related_properties;
        if (this.data.length > 0) {
          this.dataFoundStatus = "true";
          this.totalResult = resp.data.related_properties_num;
          this.totalPages = Math.ceil(this.totalResult / this.options.items);
          this.data.map((c) => {
            c.characteristicsArray = [
              {
                image: "realEstateSilder/bedroom.png",
                value: this.getCharacteristicValue(
                  c.characteristics,
                  "bedrooms"
                ),
              },
              {
                image: "realEstateSilder/bathroom.png",
                value: this.getCharacteristicValue(
                  c.characteristics,
                  "bathrooms"
                ),
              },
              {
                image: "realEstateSilder/sqft.png",
                value: c?.Unit ? c?.Unit + " " + i18n.t("sqftTable") : "-",
              },
            ];
          });
        } else {
          this.dataFoundStatus = "false";
          this.totalResult = 0;
          this.totalPages = 0;
        }
        this.loading = false;
      } catch (e) {
        this.dataFoundStatus = "error";
        this.data = [];
        this.loading = false;
        this.totalResult = 0;
        this.totalPages = 0;
        throw e;
      }
    },
  },
};
</script>

<style scoped>
.footerCl {
  color: #3a3a40;
  font-size: 14px !important;
  font-family: CenturyGothic;
}
</style>
