<template>
    <v-row v-if="data.source || data.directSource || data.addedFrom || data.sourceDescription">
        <v-col align="left">
            <v-row>
                <v-col align="left" class="font14 textInterBold goldText">
                    <span class="font14 textInterBold goldText"> {{$t("demandOriginated")}} </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12"
                    v-if="data.source">
                    <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                        :fontFamilyValue="fontFamilyValue" :label="$t('source')" :data="data.source" :isUnderline="false" />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" v-if="data.directSource">
                    <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                        :fontFamilyValue="fontFamilyValue" :label="$t('directSource')" :data="data.directSource"
                        :isUnderline="false" />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" v-if="data.addedFrom">
                    <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                        :fontFamilyValue="fontFamilyValue" :label="$t('addedBy')" :data="data.addedFrom"
                        :isUnderline="false" />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" v-if="data.sourceDescription">
                    <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                        :fontFamilyValue="fontFamilyValue" :label="$t('descriptionSource')" :data="data.sourceDescription"
                        :isUnderline="false" />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
/*
 Name:DemandOrigin
 Description: Component that displays the data of demand Origin using ReadField

 API Property:
 data: Object containing the data of Demand Origin. Example:
    {
        source: 'Απευθείας',
        directSource: "Δια ζώσης",
        addedFrom: "Δια ζώσης",
        sourceDescription: "Lorem ipsum dolor sit amet, consetetur",
    }

  Components Used:
    ReadField
*/
import ReadField from "@/components/Fields/ReadField/ReadField";
export default {
    name: "DemandOrigin",
    props: ["data"],
    components: {
        ReadField,
    },
    data() {
        return {
            fontSizeRF: "14px", //font-size for ReadingField component
            fontFamilyLabel: "InterBold", //font-family for ReadingField component label
            fontFamilyValue: "InterMedium", //font-family for ReadingField component value
            fontSizeValue: "13px"
        };
    },
}
</script>