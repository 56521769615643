<template>
  <v-col>
    <v-row>
      <v-col align="left" class="pa-0">
        <v-row>
          <v-col class="font14 textInterBold goldText">
            <span> {{ $t("realEstateSpecifications") }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="12"
            v-if="true == checkShow('type_of_property')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('realEstateType')"
              :isUnderline="false"
              :data="data.type_of_property ? data.type_of_property : ''"
            />
          </v-col>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="12"
            v-if="true == checkShow('business_type')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('businessType')"
              :isUnderline="false"
              :data="data.business_type ? data.business_type : ''"
            />
          </v-col>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="12"
            v-if="true == checkShow('constructionText')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('construction')"
              :isUnderline="false"
              :data="data.constructionText ? data.constructionText : ''"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="12"
            v-if="true == checkShow('condition')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('condition')"
              :isUnderline="false"
              :data="data.condition ? data.condition : ''"
            />
          </v-col>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="12"
            v-if="true == checkShow('floors')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('floors')"
              :isUnderline="false"
              :data="data.floors ? data.floors : ''"
            />
          </v-col>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="12"
            v-if="true == checkShow('area')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('areasqm')"
              :isUnderline="false"
              :data="data.area ? data.area : ''"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="12"
            v-if="true == checkShow('rooms')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('bedrooms')"
              :isUnderline="false"
              :data="data.rooms ? data.rooms : ''"
            />
          </v-col>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="12"
            v-if="true == checkShow('bathroom')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('bathrooms')"
              :isUnderline="false"
              :data="data.bathroom ? data.bathroom : ''"
            />
          </v-col>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="12"
            v-if="true == checkShow('plot_area')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('plotAreasqm')"
              :isUnderline="false"
              :data="data.plot_area ? data.plot_area : ''"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="12"
            v-if="true == checkShow('availability')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('availability')"
              :isUnderline="false"
              :data="data.availability ? data.availability : ''"
            />
          </v-col>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="12"
            v-if="true == checkShow('airbnb')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('airbnb')"
              :isUnderline="false"
              :data="parseBoolean(data.airbnb) == true ? '' : 'X'"
              :showIcon="parseBoolean(data.airbnb) == true"
              :iconObj="
                parseBoolean(data.airbnb) == true
                  ? { icon: 'bi:check', height: 28, color: '#77BD86' }
                  : {}
              "
            />
            <!--18n-->
          </v-col>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="12"
            v-if="true == checkShow('save')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('warehouse')"
              :isUnderline="false"
              :data="parseBoolean(data.save) == true ? '' : 'X'"
              :showIcon="parseBoolean(data.save) == true"
              :iconObj="
                parseBoolean(data.save) == true
                  ? { icon: 'bi:check', height: 28, color: '#77BD86' }
                  : {}
              "
            />
            <!--18n-->
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="12"
            v-if="true == checkShow('furnished')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('furnished2')"
              :isUnderline="false"
              :data="parseBoolean(data.furnished) == true ? '' : 'X'"
              :showIcon="parseBoolean(data.furnished) == true"
              :iconObj="
                parseBoolean(data.furnished) == true
                  ? { icon: 'bi:check', height: 28, color: '#77BD86' }
                  : {}
              "
            />
          </v-col>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="12"
            v-if="true == checkShow('allow_pet')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('allowingPets')"
              :isUnderline="false"
              :data="parseBoolean(data.allow_pet) == true ? '' : 'X'"
              :showIcon="parseBoolean(data.allow_pet) == true"
              :iconObj="
                parseBoolean(data.allow_pet) == true
                  ? { icon: 'bi:check', height: 28, color: '#77BD86' }
                  : {}
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            xl="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
            v-if="true == checkShow('description')"
          >
            <ReadField
              :fontSize="fontSizeRF"
              :fontSizeValue="fontSizeValue"
              :fontFamilyLabel="fontFamilyLabel"
              :fontFamilyValue="fontFamilyValue"
              :label="$t('description')"
              :isUnderline="false"
              :data="data.description ? data.description : ''"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="mobileBreakpoint == true && topShow.showMore != undefined"
          class="mt-0 justify-center"
        >
          <v-col class="pt-0 pb-0" align="center">
            <v-btn
              class="primaryColor unsetCapitalize opacity1 textDecorationBold"
              text
              plain
              @click="() => (topShow.showMore = !topShow.showMore)"
            >
              {{
                topShow.showMore == true ? $t("lessInfo") : $t("moreInfo")
              }}...
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
/*
 Name:EstateSpecification
 Description: Component that shows data of the demand

 API Property:
 data: Object containing the detail data of the demand
    Examle: data:{
                type_of_property: 'Κατοικία',
                business_type: 'Κτίριο Πολυκατοικία',
                condition: 'Νεόδμητο | Υπό κατασκευή',
                floors: 'Ισόγιο - 6ος',
                area: '60m2 - 250m2',
                rooms: '2-3',
                bathroom: '2-3',
                plot_area: '200m2 - 300m2',
                availability: 'Ελεύθερο',
                airbnb: true,
                save: 'false',
                furnished: true,
                allow_pet: true,
                description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy"
            },
      
    Components Used:
        ReadField
*/
import ReadField from "@/components/Fields/ReadField/ReadField.vue";
import CoreHelper from "@/helpers/Core/core.js";
// import i18n from "@/i18n/i18n";
export default {
  name: "EstateSpecification",
  components: {
    ReadField,
  },
  props: {
    data: Object,
  },
  created() {
    if (this.mobileBreakpoint == true) {
      this.topShow = CoreHelper.showObjects(this.data, this.topArray, [], 4);
    }
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  data() {
    return {
      topArray: [
        "type_of_property",
        "business_type",
        "condition",
        "floors",
        "area",
        "rooms",
        "bathroom",
        "plot_area",
        "availability",
        "airbnb",
        "save",
        "furnished",
        "allow_pet",
        "description",
        "constructionText",
      ],
      topShow: {
        showKeys: [],
        showMore: undefined,
      },
      fontSizeRF: "13px", //font-size for ReadingField component
      fontFamilyLabel: "InterBold", //font-family for ReadingField component label
      fontFamilyValue: "InterMedium", //font-family for ReadingField component value
      fontSizeValue: "13px",
    };
  },
  methods: {
    parseBoolean(val) {
      return CoreHelper.parseBoolean(val);
    },
    checkShow(objKey) {
      return this.data[objKey]
        ? this.mobileBreakpoint != true
          ? true
          : (this.topShow.showKeys && this.topShow.showKeys.includes(objKey)) ||
            this.topShow.showMore != false
          ? true
          : false
        : false;
    },
  },
};
</script>

<style scoped>
</style>
