var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pb-2",attrs:{"align":"center"}},[_c('v-row',[_c('v-col',{staticClass:"pt-4",attrs:{"align":"left"}},[_c('span',{staticClass:"textFontDecoration primaryColor font14"},[_vm._v(_vm._s(_vm.$t("weMatched"))+" "+_vm._s(_vm.totalResult)+" "+_vm._s(_vm.totalResult > 0 ? _vm.$tc("properties", 2) : _vm.$tc("properties", 1))+" ")])]),_c('v-col',[_c('v-row',{staticClass:"justify-end"},[_c('v-col',{staticClass:"flex-grow-0 pr-2"},[_c('v-select',{staticStyle:{"font-size":"15px","font-family":"CenturyGothicBold","font-weight":"500"},attrs:{"color":"success","item-color":"primary","items":_vm.propertiesList,"outlined":"","hide-details":"auto","item-text":"label","item-value":"id","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"text-no-wrap",attrs:{"align":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list',[_c('v-list-item-title',[_c('v-row',[_c('v-col',{staticClass:"text-no-wrap",attrs:{"align":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])])],1)],1)],1)]}}]),model:{value:(_vm.matchingChoice),callback:function ($$v) {_vm.matchingChoice=$$v},expression:"matchingChoice"}})],1),_c('v-col',{staticClass:"flex-grow-0"},[_c('v-select',{staticClass:"textDecorationBold font14",staticStyle:{"max-width":"75px"},attrs:{"items":_vm.pagesList,"dense":"","color":"primary","item-color":"primary","height":"35","hide-details":"","outlined":"","loading":_vm.loading},on:{"change":_vm.optionsRefresh},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"text-no-wrap",attrs:{"align":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list',[_c('v-list-item-title',[_c('v-row',[_c('v-col',{staticClass:"text-no-wrap",attrs:{"align":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])])],1)],1)],1)]}}]),model:{value:(_vm.options.items),callback:function ($$v) {_vm.$set(_vm.options, "items", $$v)},expression:"options.items"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.loading || _vm.dataFoundStatus != 'true')?_c('v-row',{staticClass:"pb-7",attrs:{"justify":"center"}},[(_vm.loading)?_c('LoaderGif',{attrs:{"white":true}}):(this.dataFoundStatus == 'false')?_c('NoData'):(this.dataFoundStatus == 'error')?_c('ServerProblem'):_vm._e()],1):(!_vm.loading && _vm.dataFoundStatus == 'true')?_c('v-row',{staticClass:"noScrollBar",class:{ 'justify-center': _vm.mobileBreakpoint },style:(!_vm.mobileBreakpoint
            ? "min-height: 320px !important; overflow: auto;"
            : '')},_vm._l((_vm.data),function(d){return _c('EstateInfoCard',{key:d._id,attrs:{"image":d.MainPhoto,"styleCard":"margin: 20px 20px 20px 10px;","characteristicsArray":d.characteristicsArray,"price":d.Price
              ? new Intl.NumberFormat('en-DE').format(d.Price) +
                ' ' +
                _vm.currency
              : '',"type":d.Task,"code":d.EstateCode,"title":d.Type,"address":d.Area,"id":d._id,"inCardPhoto":false,"getSpecificEstate":_vm.getSpecificEstate,"widthCard":279,"supportSuggestionBtn":true,"supportCorspondBtn":true,"disabledSuggest":true,"disabledCorspond":true}})}),1):_vm._e()],1)],1),_c('v-row',{staticClass:"pb-3"},[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',{staticClass:"pb-6"},[(!_vm.loading || _vm.dataFoundStatus == 'true')?_c('v-footer',{staticStyle:{"background-color":"white"},attrs:{"absolute":""}},[_c('v-row',{staticClass:"mb-0",staticStyle:{"max-width":"100% !important"}},[_c('v-col',{staticClass:"main-pgt pt-4 footerCl",attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm.$t("footerP1"))+" "+_vm._s(_vm.options.page ? _vm.options.page : 0)+" "+_vm._s(_vm.$t("footerP2"))+" "+_vm._s(_vm.options.items ? _vm.options.items : 0)+" "+_vm._s(_vm.$t("footerP3"))+" "+_vm._s(_vm.totalResult ? _vm.totalResult : 0)+" "+_vm._s(_vm.$t("footerP4"))+" ")]),(_vm.data.length > 0)?_c('v-col',{staticClass:"flex-grow-0 pr-0 mr-n4 pt-0",attrs:{"align":"right"}},[_c('v-pagination',{attrs:{"total-visible":6,"length":_vm.totalPages},on:{"input":_vm.fetchMatchings},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }