<template>
    <v-col class="overflow-auto px-0">
        <v-stepper :value="selStep" :height="stepperHeight" flat tile :width="stepperWidth">
            <v-stepper-header style="height: 100% !important;">
                <v-stepper-step v-for="n, i in steps" :key="i" :complete="selStep > n.id" :step="n.id" editable
                    :class="(selectedStep >= n.id ? ' completedStepper ' : ' defaultStepper ') + (i == 0 ? ' rightArrow ' : i == steps.length -1 ? ' leftArrow ' : ' leftArrow rightArrow ')"
                    @click="clicked (n)" class="py-0 px-0 stepperClass whiteColor"
                    disabled
                    :style="'width: ' + stepperCardWidth + ' !important;'">
                    <span class="textDecorationBold font13"> {{ n.description }} </span>
                </v-stepper-step>
            </v-stepper-header>
        </v-stepper>
    </v-col>
</template>
<script>
/* 
  Name:StepperField
  Description: StepperField component to generate quickly steppers. Support step change
  API Property 
    selectedStep: String or Number containing the selected step info
    selectedStepChanged: Function to call parent
    doubleselectedStep: Function to call parent when a stepper is double clicked
    allowRoleEdit: Boolean if the user can edit the steps
    steps: Array containing all the steps. Example:[  { id: 1, description: 'Νέα' },
                                                        { id: 2, description: 'Ανατέθηκε' },
                                                        { id: 3, description: 'Επικοινωνία' },
                                                        { id: 4, description: 'Υπόδειξη' },
                                                        { id: 5, description: 'Διαπραγμάτευση' },
                                                        { id: 6, description: 'Deal' } ]
                                                        { id: 6, description: 'Απόσυρση' } ]
        stepperHeight: String or Number containing the height of the stepper
        stepperWidth: String or Number containing the width of the stepper
  */

export default {
    name: "StepperField",
    props: {
        selectedStep: [String, Number],
        selectedStepChanged: Function,
        doubleselectedStep: Function,
        steps: Array,
        stepperHeight: { type: [String, Number], default: 23 },
        stepperWidth: { type: [String, Number], default: '1080px' },
        stepperCardWidth: { type: [String, Number], default: '180px' },
        allowRoleEdit: { type: Boolean, default: true },
    },

    computed: {
        selStep() {
            return this.selectedStep;
        },
    },
    methods: {
        clicked(obj) {
            if(this.allowRoleEdit){
                if(obj.id != 1 && obj.id != this.selStep)
                this.selectedStepChanged(obj);
            }
        },
    },
}
</script>
<style>
.stepperClass .v-stepper__step__step {
    display: none !important;
}

.stepperClass .v-stepper__label {
    color: #ffffff !important;
    font-size: 13px !important;
    font-family: CenturyGothicBold !important;
    text-align: center !important;
    display: block !important
}

.defaultStepper {
    background-color: #9D9D9D !important;
}

.completedStepper {
    background-color: #263573 !important;

}

.defaultStepper.rightArrow::after {
    content: "" !important;
    width: 0 !important;
    height: 0 !important;
    min-height: 23px !important;
    border-top: 12px solid transparent !important;
    border-bottom: 12px solid transparent !important;
    border-left: 12px solid #9D9D9D !important;
    background-color: white !important;
}

.completedStepper.rightArrow::after {
    content: "" !important;
    width: 0 !important;
    height: 0 !important;
    min-height: 23px !important;
    border-top: 12px solid transparent !important;
    border-bottom: 12px solid transparent !important;
    border-left: 12px solid #263573 !important;
    background-color: white !important;
}


.defaultStepper.leftArrow::before {
    content: "" !important;
    width: 0 !important;
    height: 0 !important;
    min-height: 23px !important;
    border-top: 12px solid transparent !important;
    border-bottom: 12px solid transparent !important;
    border-left: 12px solid white !important;
    background-color: #9D9D9D !important;
}

.completedStepper.leftArrow::before {
    content: "" !important;
    width: 0 !important;
    height: 0 !important;
    min-height: 23px !important;
    border-top: 12px solid transparent !important;
    border-bottom: 12px solid transparent !important;
    border-left: 12px solid white !important;
    background-color: #263573 !important;
}
</style>