<template>
  <v-row :class="'align-center ' + rowClass">
    <v-col class="flex-grow-0" align="center">
      <v-avatar :color="backgroundColor" :size="size" :style="avatarStyle ? avatarStyle : ''">
        <span class="white--text" style="font-family: Inter; font-size: 206.25%" :style="' font-size:' + fontSizeImg"
          v-if="!isImg">
          {{ data }}
        </span>
        <v-img v-else :src="getImage(data)" :lazy-src="getImage(data)" alt="John" />
      </v-avatar>
    </v-col>
    <v-col v-if="showName" align="left" class="pl-0 pt-0 flex-grow-0 text-no-wrap">
      <v-row class="align-center">
        <v-col class="pb-1 avatarNameContent textDecorationBold " color="primary" :class="nameContentClass" :style="`font-size:14px; color:#263573;`">
          <span> {{ nameTitleContent }} </span>
        </v-col>
      </v-row>
      <v-row class="mt-1 align-center">
        <v-col class="py-0 avatarNameContent textDecorationBold" :class="nameContentClass" :style="`font-size:${this.fontSizeName} ;`">
          <span> {{ nameContent }} </span>
        </v-col>
      </v-row>
      <v-row v-if="subNameContent" class="align-center">
        <v-col class="pb-0" :class="subNameContentClass + ' myclass'">
          <span class="avatarNameContent textDecorationBold" :style="`font-size:${this.fontSizeSubName} ;`">
            {{ subNameContent }}
          </span>
        </v-col>
      </v-row>
      <v-row class="align-center" v-if="showRating && ratingPosition == false">
        <v-col class="flex-grow-0 pr-0 pb-0 pt-0" :class="ratingFieldClass">
          <RatingField :val="ratingData" :size="ratingStars" :disabled="true" :halfIncrements="halfIncrements"
            :colorRating="colorRating" :starSize="starSize" />
        </v-col>
        <v-col align="left" class="pl-0 pb-0 pt-0" v-if="ratingStars == 1">
          <span class="textInterMedium font14" style="color: #243774">
            {{ ratingData }}
          </span>
        </v-col>
      </v-row>
      <v-row v-if="showSubContent">
        <v-col v-if="subContentIconObj"
          :class="subContentIconObj && subContentIconObj.colClass ? subContentIconObj.colClass : ''">
          <Icon :icon="subContentIconObj.icon" :height="subContentIconObj.height" v-bind:[subContentIconObj.attr]="true"
            :color="subContentIconObj.color" />
        </v-col>
        <v-col class="pt-0 pb-0" :class="subContentClass">
          <span class="avatarSubContent" :style="'font-size: ' + fontSizeSubContent">
            {{ subContent }}
          </span>
        </v-col>
        <v-col class="pt-0 pb-0" v-if="subContentExtra" :class="subContentExtraClass">
          <span class="avatarSubContent" :style="'font-size: ' + fontSizeSubContent">
            {{ subContentExtra }}
          </span>
        </v-col>
        <v-col v-if="showRating == true && ratingPosition == true" class="flex-grow-0 pr-0 pb-0 pt-0"
          :class="ratingFieldClass">
          <RatingField :val="ratingStars != 1 ? ratingData : 0" :size="ratingStars" :disabled="true"
            :halfIncrements="halfIncrements" :colorRating="colorRating" :starSize="starSize" />
        </v-col>
        <v-col v-if="ratingStars == 1 && ratingPosition == true" align="left"
          class="pl-0 pb-0 pt-0 textInterMedium font14" style="color: #243774">
          <span> {{ ratingData }} </span>
        </v-col>
      </v-row>
      <v-row v-if="showIcon">
        <v-col class="pt-0 pb-0">
          <Icon :icon="iconObj.icon" :height="iconObj.height" v-bind:[iconObj.attr]="true" :color="iconObj.color" />
        </v-col>
      </v-row>
      <v-row v-if="showDealInfo">
        <v-col class="pt-0">
          <span class="textDecoration" style="
              background: #ffffff;
              color: #263573;
              border-style: groove;
              border-color: rgb(255 255 255);
              padding: 1px 7px;
              font-size: 13px;
            ">
            {{ $t("informationDeal") }}
          </span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
/* 
   Name:Avatar Field
   Description: Avatar component to show img or name. Has details info to show full name, rating and subcontent.
   It is the same component with the avatarfield but it is more statistic for the need of the demands. 
   
   API Property:
   rowClass: String containing classes for the master row
   backgroundColor: background color of avatar
   data: String containing image path or name initials
   absolutePath: boolean indicating if path is absolute or not,
   isImg: If component render img or name
   showName: Show/hide name info
   nameTitleContent: title above the name of the person
   nameContent: fullname of person
   nameContentClass: Strubg containing classes for name content
   subNameContent: subname info of person,
   subNameContentClass: String containing classes for sub name content
   fontSizeSubName: font size for the sub name,
   showRating: Show/hide rating component
   starSize: Size of star
   ratingStars: Show how many stars will be rendered,
   ratingFieldClass: Class for rating field col
   ratingData: Number of rating
   colorRating: Color of the rating stars
   halfIncrements: Boolean that enables or disables half increments
   showSubContent: show/hide subContent area
   subContent:String containing sub content value
   subContentClass : String containing sub content classes
   subContentExtra : String containing sub content extra value
   subContentExtraClass : String containing sub content extra classes
   subContentIconObj: object of sub content icon
              iconObj: {
                  icon: "ant-design:up-outlined",
                  height: 15,
                  attr: outline,
                  color: "white"
              }
   showDealInfo: show/hide dealInfo area
   showIcon: show/hide icon area
   iconObj: object of icon
              iconObj: {
                  icon: "ant-design:up-outlined",
                  height: 15,
                  attr: outline,
                  color: "white"
              }
   height: height of avatar
   width: width of avatar
   fontSizeImg: font size for the image area when no image found,
   fontSizeName: font size for the name
   fontSizeSubContent: front size for sub content
   ratingPosition: If true, rating star will be in the same line as subContent

  Components Used:
    RatingField
    Icon
*/
import RatingField from "@/components/Fields/RatingField/RatingField.vue";
import { Icon } from "@iconify/vue2";
import CoreHelper from "@/helpers/Core/core.js";
export default {
  name: "DemandAvatar",
  props: {
    rowClass: String,
    backgroundColor: { type: String, default: "#263573" },
    isImg: Boolean,
    data: String,
    absolutePath: {type: Boolean, default :false},
    showName: { type: Boolean, default: false },
    nameTitleContent: String,
    nameContent: String,
    nameContentClass: String,
    subNameContent: String,
    subNameContentClass: String,
    fontSizeSubName: { type: String, default: "14px" },
    showRating: { type: Boolean, default: false },
    starSize: { type: Number, default: 24 },
    ratingFieldClass: String,
    ratingData: { type: [String, Number], default: null },
    ratingStars: { type: Number, default: 1 },
    colorRating: String,
    halfIncrements: { type: Boolean, default: true },
    showSubContent: { type: Boolean, default: false },
    subContent: String,
    subContentClass: String,
    subContentExtra: String,
    subContentExtraClass: String,
    subContentIconObj: Object,
    showDealInfo: { type: Boolean, default: false },
    showIcon: { type: Boolean, default: false },
    iconObj: Object,
    size: { type: [String, Number], default: "75" },
    // height: { type: [String, Number], default: "75px" },
    // width: { type: [String, Number], default: "75px" },
    fontSizeImg: { type: [String, Number], default: "33px" },
    fontSizeName: { type: String, default: "16px" },
    fontSizeSubContent: { type: String, default: "14px" },
    ratingPosition: { type: Boolean, default: false },
    avatarStyle: String,
  },
  components: {
    RatingField,
    Icon,
  },
  methods: {
    getImage(item) {
      return CoreHelper.getImagePath({
        path: item,
        absolutePath: this.absolutePath
      });
    },
  },
};
</script>
