export default {
    offerStatusList: [
        {
          text: "oralOffer",
          value: 0,
        },
        {
          text: "writtenOffer",
          value: 1,
        },
    ],
};